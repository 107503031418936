.custom-cards {
	flex: 1;
	height: 100% !important;
	padding: 20px;
	display: flex;
	justify-content: center;
	.points-detail {
		width: 100%;
		.reward-point {
			width: 30%;
			text-align: center;
		}
	}
	.about-points {
		// width: 70% !important;
		text-align: center !important;
		.point-amount {
			line-height: 1.5rem !important;
		}
		.point-info {
			line-height: unset !important;
		}
	}
}
