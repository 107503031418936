.main-slider-container {
	.slider-badges-svg {
		text-align: center;
		.img-label {
			margin-top: 10px;
			color: #1d8627;
			font-size: 14px;
			font-weight: bold;
		}
		span {
			padding: 5px 9px;
			background-color: #f4efee;
			display: flex;
			border-radius: 50px;
			margin: 10px 0 0 0;
			font-weight: bold;
			font-size: 13px;
		}
	}

	.main-slider-container {
		.slick-track {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.img-label {
			width: 64px;
		}
	}
}

.physical-reward-modal {
	.physical-reward-container {
		width: 100%;
	}
	.physical-reward-badge {
		flex-direction: column;
		text-align: center;
	}
}

.nomintaion-rewards-list {
	.nominated-badges-container {
		cursor: pointer !important;
	}
	.badges-svg {
		text-align: center !important;
	}
	.slick-next {
		right: 0;
	}

	.badge-slider {
		width: unset !important;
	}

	.slick-prev {
		left: 0 !important;
	}

	.slick-arrow:before,
	.slick-arrow:after {
		background-color: blue;
	}
}

.winner-list-modal {
	.physical-reward-badge {
		padding-left: 0 !important;
	}

	.winner-list {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.ant-modal-body {
		height: auto !important;
	}
}

.reward-winner {
	.badge-list {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		column-gap: 16px;
		row-gap: 16px;

		@media (max-width: 1300px) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
		@media (max-width: 1100px) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		@media (max-width: 1024px) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
		@media (max-width: 480px) {
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}
	}
}

.nomination-height {
	height: 250px;
}
