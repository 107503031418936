.reward-modal .ant-modal-title {
	text-align: left;
}
.reward-modal {
	.ant-modal-body {
		height: 100%;
		overflow-y: auto;
	}
}
.error-tooltip {
	.ant-tooltip-inner {
		background-color: rgba(0, 0, 0, 0.85);
		color: #fff;
	}

	.ant-tooltip-arrow::before {
		background-color: rgba(0, 0, 0, 0.85);
	}
}
.list-img {
	img {
		width: 24px;
		object-fit: contain;
		height: 24px;
	}
}

.add-edit-reward-loader {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: rgba(249, 249, 249, 0.5);
	border-radius: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.reward-modal {
	.custom-form-group {
		.custom-label {
			width: 130px;
		}
	}
	.ant-form-item-control-input-content {
		display: flex;
		align-items: center;
	}
}
.permission-detail {
	.custom-form-group {
		.custom-label {
			width: 250px;
		}
	}
}
.asterisk {
	color: red !important;
}

.ant-tooltip {
	.ant-tooltip-inner {
		background-color: rgba(0, 0, 0, 0.85);
		color: #fff;
	}

	.ant-tooltip-arrow::before {
		background-color: rgba(0, 0, 0, 0.85);
	}
}

.reward-modal .custom-form-group .ant-form-item-children {
	align-items: center;
}

.reward-modal .ant-modal-header {
	padding: 20px 20px 8px 20px;
}

.reward-modal .ant-tooltip-content .ant-tooltip-arrow::before {
	background-color: rgba(0, 0, 0, 0.85);
}

.reward-modal .ant-tooltip-content .ant-tooltip-inner {
	background-color: rgba(0, 0, 0, 0.85);
	color: #fff;
}

.custom-selection.css-2b097c-container .css-yk16xz-control {
	height: 40px !important;
}

.custom-selection.css-2b097c-container .css-g1d714-ValueContainer {
	height: 40px;
}

.custom-selection.css-2b097c-container .css-1wa3eu0-placeholder {
	line-height: normal;
}

.award-panel.ant-collapse-borderless > .ant-collapse-item {
	border: none !important;
}

.award-panel.ant-collapse
	> .ant-collapse-item
	> .ant-collapse-header
	.ant-collapse-arrow {
	position: inherit;
	top: inherit;
	left: inherit;
	transform: inherit;
	margin-left: 10px;
}

.award-panel.ant-collapse
	> .ant-collapse-item:last-child
	> .ant-collapse-header {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row-reverse;
	color: #95979a;
}

.collpse-btn {
	cursor: pointer;
	background-color: #ffffff;
	// z-index: 9;
}

.collpse-btn:hover,
.collpse-btn:active,
.collpse-btn:focus {
	background: none !important;
	color: #95979a;
	border-color: transparent !important;
}

.grid-7 {
	grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-5 {
	grid-template-columns: repeat(5, minmax(0, 1fr));
}

.spinner-height {
	min-height: 200px;
	width: 100% !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.spinner {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}

.add-reward-preview {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	// width: calc(100% - 100px);
}

.reward-svg {
	height: 80%;
	display: flex;
	align-items: center;

	svg {
		width: 166px !important;
		height: 144px;
	}
}

.preview-label {
	margin-top: 64px !important;
}

// .reward-svg {
// 	border: 1px solid red;
// 	position: relative;

// 	.label {
// 		position: absolute;
// 		background-color: rgb(68, 67, 67);
// 		color: white;
// 		font-weight: 600;
// 		width: 128px;
// 		padding: 4px;
// 		border-radius: 4px;
// 		text-align: center;
// 		right: 0;
// 		top: 0;
// 	}
// }
.hello-tabs {
	background-color: red;
	height: 100px;
}

.color-card {
	margin-bottom: 0;
	align-items: center;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px 10px;
	text-align: center;
	color: #fff;

	h4 {
		color: #fff;
		font-size: 20px;
		font-weight: 600;
		line-height: 24px;
		margin: 0 0 5px;
	}
	p {
		color: #fff;
    	font-size: 14px;
    	line-height: 18px;
    	margin: 0;
	}
}
.contact-color-card {
	column-gap: 10px;
	display: flex;
}