.action-item {
	width: 100%;
	justify-content: unset !important;
	display: block !important;
	.send-points {
		padding: 12px !important;
		background-color: #ff4d4f !important;
		width: 100% !important;
		border-radius: 8px;
		.points-detail {
			width: 100%;
			display: flex;
			@media (min-width: 320px) and (max-width: 1200px) {
				flex-wrap: wrap;
			}
			.reward-point-img {
				width: 130px;
				text-align: center;
				@media (min-width: 320px) and (max-width: 1200px) {
					width: 100%;
				}

				img {
					max-width: 80px;
				}
			}
			.about-points {
				// width: calc(100% - 200px);
				text-align: center;
				font-size: 16px;
				color: #fff;
				@media (min-width: 320px) and (max-width: 1200px) {
					width: 100%;
				}
				.point-amount {
					color: #fff;
					margin-bottom: 5px;
					font-size: 16px;
					font-weight: bold;
				}
				.point-info {
					font-size: 12px;
				}
				.info-bold {
					font-weight: bold;
				}
			}
			.button-container {
				display: flex;
				gap: 10px;
				justify-content: center;
				// flex-wrap: wrap;
				margin-top: 5px;
				.ant-btn {
					padding: 4px 16px;
					font-size: 12px;
				}
				.decline-button {
					border-color: white;
					color: white;
				}
			}
		}
	}
}
.rewards-points {
	.no-data-found {
		padding: 0;
	}
}
