@import '../public/assets/scss/root';
@import '../public/assets/scss/common';
@import '../public/assets/scss/typography';
@import '../public/assets/scss/auth';
@import '../public/assets/scss/header';
@import '../public/assets/scss/sidebar';
@import '../public/assets/scss/dashboard';
@import '../public/assets/scss/dashboard_new';
@import '../public/assets/scss/announcement';
@import '../public/assets/scss/careers';
@import '../public/assets/scss/jobs';
@import '../public/assets/scss/referral';
@import '../public/assets/scss/referrals';
@import '../public/assets/scss/bonus';
@import '../public/assets/scss/contact';
@import '../public/assets/scss/profile';
@import '../public/assets/scss/employees';
@import '../public/assets/scss/messages';
@import '../public/assets/scss/settings';
@import '../public/assets/scss/notifications';
@import '../public/assets/scss/button';
@import '../public/assets/scss/icons';
@import '../public/assets/scss/modals';
@import '../public/assets/scss/store';
@import '../public/assets/scss/internal';
@import '../public/assets/scss/custom';
@import '../public/assets/scss/report_builder';
@import '../public/assets/scss/export_selector';

@import '../public/assets/scss/reward';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
