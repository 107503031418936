.badges-list-flex {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	width: 100%;
	.badges-container {
		min-width: fit-content;
		width: calc(20% - 10px);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.badges-img {
			text-align: center;
			.badges-svg {
				width: auto;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.img-label {
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				width: 100px;
				margin: 0 auto;
				margin-top: 5px;
				word-break: break-word;
			}
		}
	}
	.badges-container-edit-target {
		padding-top: 10px;
		padding-bottom: 10px;
		border: 1px solid #fff;
		transition: box-shadow 0.3s;
	}
	.badges-container-edit-target:hover {
		border-radius: 10px;
		border: 1px solid #ccc;
		box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
	}
}

/* Media query for large-sized desktop screens */
@media (min-width: 1920px) {
	.badges-container {
		width: calc(12.5% - 10px) !important;
	}
}

/* Media query for large desktop screens */
@media (max-width: 1600px) {
	.badges-container {
		width: calc(16.66% - 10px) !important;
	}
}

/* Media query for medium-sized screens */
@media (max-width: 1599px) {
	.badges-list-automated .badges-container {
		width: calc(20% - 10px) !important;
		column-gap: 10px;
	}
	.badges-container {
		width: calc(17% - 10px) !important;
	}
}

/* Media query for medium-sized screens */
@media (max-width: 1200px) {
	.badges-container {
		width: calc(20% - 10px) !important;
	}
}

/* Media query for small desktop screens */
@media (max-width: 992px) {
	.badges-container {
		width: calc(33.33% - 10px) !important;
	}
}

/* Media query for tablets */
@media (max-width: 768px) {
	.badges-container {
		width: calc(33.33% - 10px) !important;
	}
}

/* Media query for mobile devices */
@media (max-width: 576px) {
	.badges-container {
		width: 100% !important;
	}
}
