.auth-wrapper {

    .container-fluid {
        & > .row {
            & > .col-12 {
                padding: 0;
            }
        }
    }
    .content-area {
        padding: 0;
    }

    .auth {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: space-between;
        background-position: center center;
        background-size: cover;
        position: relative;

        .auth-left {
            flex: 0 0 50%;
            position: relative;
            display: flex;
            flex-direction: column;

            .auth-logo-wrap {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                .auth-logo {
                    width: 260px;

                    &.whitelabel-logo {
                        width: 360px;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            .auth-bottom-links {
                left: 0;
                bottom: 0;
                position: absolute;
                width: 100%;
                background: #012140;
                padding: 8px 0;
                text-align: center;

                .auth-links-info {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        color: #fff;
                        padding: 0 14px;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 400;
                        display: flex;
                        align-items: center;

                        & + li {
                            border-left: 1px solid #fff;
                        }
                        span {
                            margin-right: 10px;
                        }
                        a {
                            color: #fff;
                            text-decoration: underline;
                            margin-right: 10px;

                            &:last-child {
                                margin-right: 0px;
                            }
                        }
                    }
                }
            }
        }
        .auth-right {
            flex: 0 0 50%;
            padding: 30px 30px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            background-color: rgba(255, 255, 255, 0.5);

            .auth-card {
                padding: 24px 30px;
                background: #FFFFFF;
                border: 1px solid #DDDDDD;
                border-radius: 16px;
                max-width: 440px;
                width: 100%;
                position: relative;

                .auth-title {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: bold;
                    color: #000000;
                    margin: 0 0 16px;
                }

                .auth-sub-title {
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: bold;
                    color: #000000;
                    margin: -10px 0 20px;
                }

                .forgot-link {
                    text-align: right;
                    margin: -10px 0 20px;
                }

                .claim-your-account {
                    padding: 6px 0px 0px;
                    text-align: center;
                    margin: 20px 30px 20px 30px;
                }
                .by-continuing-links {
                    margin: 0px;
                }

                .or-style {
                    margin: 10px 0;
                    font-size: 14px;
                    line-height: 18px;
                    color: #000000;
                    text-align: center;
                }
                .ant-alert {
                    margin-bottom: 16px;
                }
                
            }
        }
    }
}

@media (max-width: 767px) {
    .auth-wrapper {
        .auth {
            padding-bottom: 60px;
            flex-direction: column;

            .auth-left {
             position: static;   
            }
        }
    }
}