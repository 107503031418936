.ant-tabs-ink-bar {
	z-index: 0;
}
.feed-reward-logo {
	// width: 28px;
	// height: 28px;
	// margin: 0 10px;
	svg {
		height: 28px;
		width: 28px;
	}
}
.send-modal-btn {
	display: inline-flex !important;
	.send-modal-container {
		display: flex;
		gap: 5px;
		flex-wrap: wrap;
		.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
			min-width: 150px;
			border-radius: 24px;
			border: 1px solid #e8e8e8;
			color: rgba(0, 0, 0, 0.25);
			font-size: 14px;
			font-weight: 700;
			background-color: #e8e8e8;
		}
	}
}
.ant-select::selection {
	background: none;
}
.ant-select-single {
	.ant-select-selector {
		.ant-select-selection-placeholder {
			filter: grayscale(100%);
			img {
				opacity: 0.5;
			}
		}
	}
}

@media only screen and (max-width: 1400px) {
	.send-modal-btn {
		flex-direction: column;
	}
}

.badge-box {
	border-radius: 24px;
	border: none;
	color: #fff !important;
	font-size: 14px;
	font-weight: 700;
	height: 34px;
	padding: 4px 11px;
	background-color: var(--primary-btn-color, var(--theme-primary));

	.text-box {
		column-gap: 10px;
		span.selected-badge-name {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			// width: 100px;
		}
		.anticon-close {
			cursor: pointer;
		}
	}
}

.employee-option-rr {
	&.ant-select-item-option-grouped {
		padding-left: 10px;
	}
}
.employee-option-rr > .ant-select-item-option-content {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	overflow: hidden;
}
.employee-option-text-rr {
	margin-left: 4px;
	font-size: 13px;
	color: var(--dove-gray);
	font-weight: 300;
}
.employee-group-label-rr {
	color: var(--dove-gray);
	font-weight: 700 !important;
	font-size: 14px;
}
.ant-popover {
	z-index: 1050;
}

.dropdown-wrapper {
  display: inline-block;
}

.badge-box {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  height: 34px;
  border: 1px solid var(--primary-btn-color, var(--theme-primary));
  min-width: 150px;
  transition: background-color .3s ease;
  background-color: var(--primary-btn-color, var(--theme-primary));
  &:hover {
	transition: background-color .3s ease;
    background-color: var(--primary-btn-highlight-color, var(--theme-primary-highlight));
	border-color: var(--primary-btn-highlight-color, var(--theme-primary-highlight));
  }
}

.dropdown-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-content {
  position: absolute;
  height: 34px;
  top: 50%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateY(-50%);
  white-space: nowrap;
  gap: 4px;
}

.dropdown-content.hidden {
  opacity: 0;
  transform: translateY(-150%);
}

.hover-content {
  opacity: 0;
  transform: translateY(150%);
  transition: opacity 0.3s, transform 0.3s;
}

.hover-content.visible {
  opacity: 1;
  transform: translateY(-50%);
}

.dropdown-text, .remove-text {
  white-space: nowrap;
}

.at-symbol-icon-filled {
	color: #fff;
}

.send-modal-container {
	div {
		.ant-select {
			.ant-select-selector {
				height: 34px;
			}
		}
	}
}