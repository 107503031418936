.feed-container{
    .feed-reward-logo{
        svg{
            height: 30px;
            width: 30px;
            transition: transform .3s;
            &:hover {
                transform: scale(3.5);
            }
        }
    }
    .clap-icon{
        svg{
            fill: red
        }
    }
}